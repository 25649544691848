import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import GlobalContext from "../contexts/GlobalContext"
import { allLangs } from "../utils/configClient"

import PageWrapper from "../components/PageWrapper"
import HeroSlider from "../sections/home/HeroSlider"
import About from "../sections/home/About"
import Cartificates from "../sections/home/Cartificates"
import Quote from "../sections/home/Quote"
import Products from "../sections/home/Products"
import Support from "../sections/common/Support"
import News from "../sections/home/News"
import Partners from "../sections/home/Partners"
import Representatives from "../sections/home/Representatives"
import Contact from "../sections/common/Contact"

import { itemsByLang } from "../utils/helperFn"

const IndexPage = ({ data }) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.currentLang !== allLangs.cn) {
      gContext.setCurrentLang(allLangs.cn)
    }
  }, [])

  const prismicHome = itemsByLang(
    data.allPrismicHomePage.nodes,
    gContext.currentLang
  )[0]

  const { meta_title, meta_description } = prismicHome?.data

  return (
    <>
      <GatsbySeo
        title={meta_title?.text}
        description={meta_description?.text}
      />
      <PageWrapper>
        <div id="hero">
          <HeroSlider />
        </div>

        <div id="about">
          <About />
          <div id="certs" className="pt-5">
            <div className="pt-5 mt-5">
              <Cartificates />
            </div>
          </div>
        </div>
        <div id="quote">
          <Quote />
        </div>
        <div id="products">
          <Products />
        </div>
        <div id="support">
          <Support />
        </div>
        <div id="news">
          <News />
        </div>
        <div id="partners">
          <Partners />
        </div>
        <div id="representatives">
          <Representatives />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapper>
    </>
  )
}
export default IndexPage

export const query = graphql`
  query {
    allPrismicHomePage {
      nodes {
        lang
        data {
          meta_title {
            text
          }
          meta_description {
            text
          }
        }
      }
    }
  }
`
